import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

/**
 * @class Slider
 * 
 * @method constructor
 * @method setRendererSize
 * @method setRendererOptions
 * @method createCamera
 * @method updateCamera
 * @method buildScene
 * @method animateScene
 *
 * Create a default Three.js scene
 */
export default class Scene {
    /**
     * @method constructor
     * 
     * @param {object} config - The scene configuration
     */
    constructor (config = {}) {
        const {
            canvas,
            sizes,
            ratio,
            fov,
            controls = true
        } = config;

        this.config = {};
        this.config.canvas = canvas;
        this.config.sizes = sizes;
        this.config.ratio = ratio;
        this.config.fov = fov;
        this.config.controls = controls;

        this.buildScene();
    }

    /**
     * @method setRendererSize
     */
    setRendererSize () {
        this.renderer.setSize(this.config.sizes.width, this.config.sizes.height);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 3));
    }

    /**
     * @method setRendererOptions
     */
    setRendererOptions () {
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFShadowMap;

        this.renderer.outputEncoding = THREE.sRGBEncoding;
        this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
        this.renderer.toneMappingExposure = 1.2;
    }

    /**
     * @method createCamera
     * 
     * @return {object} The scene camera
     */
    createCamera () {
        const camera = new THREE.PerspectiveCamera(this.config.fov, this.config.ratio, 0.1, 100);
        camera.position.set(1, 1, 1);
        return camera;
    }


    /**
     * @method UpdateCamera
     *
     * Update camera on window resize.
     * Prevent cropping the scene while preserving the screen ratio.
     */
    updateCamera () {
        this.camera.aspect = this.config.ratio;

        if (this.camera.aspect > 1) {
            // Window too large
            this.camera.fov = this.config.fov;
        } else {
            // Window too narrow
            const cameraHeight = Math.tan(THREE.MathUtils.degToRad(this.config.fov / 2));
            const realRatio = this.camera.aspect / 1;
            const newCameraHeight = cameraHeight / realRatio;
            this.camera.fov = THREE.MathUtils.radToDeg(Math.atan(newCameraHeight)) * 2;
        }

        this.camera.updateProjectionMatrix();
    }
    
    /**
     * @method buildScene
     */
    buildScene () {
        this.scene = new THREE.Scene();

        this.camera = this.createCamera();

        this.scene.add(this.camera);


        this.renderer = new THREE.WebGLRenderer({
            canvas: this.config.canvas,
            antialias: true,
            powerPreference: 'high-performance'
        });

        this.setRendererOptions();
        this.setRendererSize();
        this.updateCamera();

        window.addEventListener('resize', () => {
            // Prevent scene from resizing when the navbar pops up in mobile.
            if (document.documentElement.offsetWidth === this.config.sizes.width && Math.abs(this.config.sizes.height - window.innerHeight) < 200) return;
            // Update sizes
            this.config.sizes.width = document.documentElement.offsetWidth;
            this.config.sizes.height = window.innerHeight;
            this.config.ratio = this.config.sizes.width / this.config.sizes.height;

            // Update camera
            this.updateCamera();

            // Update renderer
            this.setRendererSize();
        });

        // Controls
        if (this.config.controls) {
            this.controls = new OrbitControls(this.camera, this.config.canvas);
            this.controls.enableDamping = true;
            this.controls.minDistance = 1.5;
            this.controls.maxDistance = 50;
        }
    }
}