const build = container => {
    const placeholder = container.querySelector('.js-lazyload-placeholder');
    const image = new Image();
    const src = placeholder.src;
    const alt = placeholder.alt;
    let ratio = placeholder.height / placeholder.width;
    container.style.height = placeholder.height + 'px';

    const imageCanvas = document.createElement('canvas');
    imageCanvas.width = placeholder.clientWidth;
    imageCanvas.height = placeholder.clientWidth * ratio;
    const ctx = imageCanvas.getContext('2d');
    const img = new Image();
    img.src = src;

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const lazyImage = entry.target;
                lazyImage.src = placeholder.dataset.src;
                lazyImage.alt = alt;
                lazyImage.classList.add('c-lazyload__image');
                lazyImage.classList.add('c-lazyload__image', 'c-intro__img');
                observer.unobserve(lazyImage);
            }
        });
    }, {
        rootMargin: '0% 0% -50% 0%'
    });

    let imageData;
    const edges = [];
    const loadImage = () => {
        observer.observe(image);
        imageCanvas.style.opacity = 1;
        ratio = placeholder.height / placeholder.width;
        imageCanvas.width = placeholder.clientWidth;
        imageCanvas.height = placeholder.clientWidth * ratio;
        container.style.height = placeholder.height + 'px';
        placeholder.remove();
        ctx.drawImage(img, 0, 0, imageCanvas.width, imageCanvas.height);
        imageData = ctx.getImageData(0, 0, imageCanvas.width, imageCanvas.height);
        for (let j = 0; j < imageCanvas.height; j++) {
            let firstColor = [];
            let lastColor = [];
            for (let i = j * (imageCanvas.width * 4); i < (j + 1) * (imageCanvas.width * 4); i += 4) {
                const red = imageData.data[i];
                const green = imageData.data[i + 1];
                const blue = imageData.data[i + 2];
                const alpha = imageData.data[i + 3];
                if (alpha > 254) { // not transparent
                    if (firstColor.length === 0) {
                        firstColor = [red, green, blue, i];
                    }
                    lastColor = [red, green, blue, i];
                }
            }
            edges.push([firstColor, lastColor]);
        }
        for (let j = 0; j < imageCanvas.height; j++) {
            for (let i = j * (imageCanvas.width * 4); i < (j + 1) * (imageCanvas.width * 4); i += 4) {
                const alpha = imageData.data[i + 3];
                if (alpha < 255) {
                    const leftEdge = edges[j][0] ?? [0, 0, 0, i];
                    const rightEdge = edges[j][1] ?? [0, 0, 0, i];
                    if (i < leftEdge[3]) {
                        imageData.data[i] = leftEdge[0];
                        imageData.data[i + 1] = leftEdge[1];
                        imageData.data[i + 2] = leftEdge[2];
                        imageData.data[i + 3] = 255;
                    } else if (i > rightEdge[3]) {
                        imageData.data[i] = rightEdge[0];
                        imageData.data[i + 1] = rightEdge[1];
                        imageData.data[i + 2] = rightEdge[2];
                        imageData.data[i + 3] = 255;
                    }
                }
            }
        }
        ctx.putImageData(imageData, 0, 0);
    }

    let translation = 0;
    const animate = () => {
        translation += 20;
        for (let j = 0; j < imageCanvas.height; j++) {
            for (let i = j * (imageCanvas.width * 4); i < (j + 1) * (imageCanvas.width * 4); i += 4) {
                const leftEdge = edges[j][0] ?? [0, 0, 0, i];
                const red = leftEdge[0];
                const green = leftEdge[1];
                const blue = leftEdge[2];
                if (red + green + blue < translation) {
                    imageData.data[i + 3] = 0;
                }
            }
        }
        ctx.putImageData(imageData, 0, 0);
        if (translation < 3 * 255) {
            window.requestAnimationFrame(animate);
        } else {
            imageCanvas.remove();
        }
    }

    if (img.complete && img.naturalHeight !== 0) {
        loadImage();
    } else {
        img.addEventListener('load', () => {
            loadImage();
        });
    }

    if (image.complete && image.naturalHeight !== 0) {
        container.style.height = '';
        animate();
    } else {
        image.addEventListener('load', () => {
            container.style.height = '';
            animate();
        });
    }

    container.appendChild(image);
    container.appendChild(imageCanvas);
}

const module = () => {
    const containers = document.querySelectorAll('.js-lazyload');
    containers.forEach(build);
}

export default module;