const selectors = {
    el: '.js-blank-space'
}

let bgColor = {
    'light': {
        r: 244,
        g: 244,
        b: 241
    },
    'dark': {
        r: 23,
        g: 23,
        b: 23
    }
}

let strokeColor = {
    'light': '#171717',
    'dark': '#f4f4f1'
}

const canvas = document.querySelector(selectors.el);
const ctx = canvas.getContext('2d');

const updateContext = (scale) => {
    canvas.width = canvas.clientWidth;
    canvas.height = canvas.clientHeight;
    ctx.scale(scale, scale);
}

const module = () => {
    canvas.width = canvas.clientWidth;
    canvas.height = canvas.clientHeight;

    let lastPos = { x: 0, y: 0 };
    let fadeStep = 0;
    let timeoutFade;

    const scale = window.devicePixelRatio;
    updateContext(scale);

    window.addEventListener('resize', () => updateContext(scale));

    document.addEventListener('modeChanged', () => {
        const bg = bgColor[document.body.dataset.mode];

        ctx.fillStyle =  `rgb(${bg.r}, ${bg.g}, ${bg.b})`;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.strokeStyle = strokeColor[document.body.dataset.mode];
    });

    const onEnter = (e) => {
        lastPos.x = e.offsetX;
        lastPos.y = e.offsetY;
    }

    const onMove = (e) => {
        ctx.beginPath();
        ctx.moveTo(lastPos.x / scale, lastPos.y / scale);
        ctx.lineTo(e.offsetX / scale, e.offsetY / scale);
        ctx.stroke();

        lastPos.x = e.offsetX;
        lastPos.y = e.offsetY;

        if (timeoutFade) {
            clearTimeout(timeoutFade);
        }
        timeoutFade = setTimeout(fadeOut, 1000);
    }

    canvas.addEventListener('pointerenter', e => {
        onEnter(e);
    });

    canvas.addEventListener('pointermove', e => {
        onMove(e);
    });

    const fadeOut = () => {
        fadeStep++;

        if (fadeStep > 25) {
            fadeStep = 0;
            return;
        }

        const bg = bgColor[document.body.dataset.mode];

        ctx.fillStyle =  `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.25)`;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        setTimeout(fadeOut, 10);
    }
}

export default module;