import gsap from 'gsap';
import whatInput from 'what-input';
import * as THREE from 'three';
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';
import { MeshSurfaceSampler } from 'three/addons/math/MeshSurfaceSampler.js';
import { perlin2, seed } from '../utils/perlin-noise.js';
import { mulberry32 } from '../utils/random.js';
import Stats from 'three/addons/libs/stats.module.js';
// import * as dat from 'dat.gui';
import Scene from '../classes/Scene';
import Grass from '../classes/Grass';
// Textures
import materialColor from '../../assets/textures/concrete/basecolor.webp';
import materialHeight from '../../assets/textures/concrete/height.webp';
import materialNormal from '../../assets/textures/concrete/normal.webp';
import materialRoughness from '../../assets/textures/concrete/roughness.webp';

const classes = {
    dark: 'is-dark'
}

const sizes = {
    width: document.documentElement.offsetWidth,
    height: window.innerHeight
};

const uniforms = {
    uTime: { value: 0 }
}

let mode = 'day';

const sphereParams = {
    collapsed: {
        day: {
            x: -3,
            xDiff: 6,
            rotation: 0,
            rotationDiff: -Math.PI / 2
        },
        night: {
            x: 3,
            xDiff: -6,
            rotation: -Math.PI / 2,
            rotationDiff: Math.PI / 2
        }
    },
    expanded: {
        day: {
            x: -5,
            xDiff: 10,
            rotation: Math.PI / 2,
            rotationDiff: -3 * Math.PI / 2
        },
        night: {
            x: 5,
            xDiff: -10,
            rotation: -Math.PI,
            rotationDiff: 3 * Math.PI / 2
        }
    }
}

const lightParams = {
    day: {
        color: '#ffbf94',
        x: 100,
        y: 4,
        grassSaturation: 1,
        grassBrightness: 1,
        bgColor: new THREE.Color('#e0e2e3')
    },
    night: {
        color: '#2b2b2f',
        x: -8,
        y: 6,
        grassSaturation: 0.3,
        grassBrightness: 0.4,
        bgColor: new THREE.Color('#202528')
    }
}

const modeChangedEvent = new CustomEvent('modeChanged');

/**
 * buildScene
 */
const buildScene = () => {
    const canvas = document.querySelector('.js-canvas');
    let ratio = sizes.width / sizes.height;
    const scene = new Scene({
        canvas,
        sizes,
        ratio,
        fov: 75
    });

    /* Gui config */
    let gui;
    // gui = new dat.GUI();
    const debug = {
        envRotation: 0,
        bgColor: new THREE.Color('#e0e2e3'),
        lightX: lightParams[mode].x,
        lightY: lightParams[mode].y,
        grassSaturation: 1,
        grassBrightness: 1
    };

    /* Monitor scene performances */
    const stats = new Stats();
    // document.body.appendChild(stats.dom);
    let pxRatio = 2;
    setInterval(() => {
        const fps = stats.getFrameRate();
        if (fps < 60) {
            pxRatio -= 0.2;
            pxRatio = Math.round(Math.min(Math.max(pxRatio, 1.2), 3) * 10) / 10;
            scene.renderer.setPixelRatio(Math.min(window.devicePixelRatio, pxRatio));
        } else if (fps >= 60) {
            pxRatio += 0.2;
            pxRatio = Math.round(Math.min(Math.max(pxRatio, 1.2), 3) * 10) / 10;
            scene.renderer.setPixelRatio(Math.min(window.devicePixelRatio, pxRatio));
        }
    }, 2000);

    /* Loading manager */
    const manager = new THREE.LoadingManager();

    /* Loaders */
    const textureLoader = new THREE.TextureLoader(manager);

    /* Optimise raycasting */
    THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
    THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
    THREE.Mesh.prototype.raycast = acceleratedRaycast;

    /* Terrain */
    const ground = new THREE.Group();

    /* Terrain */
    // Geometry
    const terrainGeometry = new THREE.PlaneGeometry(40, 24, 512, 512);
    const verticesCount = terrainGeometry.attributes.position.count;

    // Material
    const terrainMaterial = new THREE.MeshStandardMaterial({
        color: '#D2B3AD',
        roughness: 0.5,
        side: THREE.DoubleSide
    });

    // Mesh
    const terrain = new THREE.Mesh(terrainGeometry, terrainMaterial);
    terrain.castShadow = true;
    terrain.receiveShadow = true;
    const normalize = (val, max, min) => (val - min) / (max - min);

    let baseColors = [];
    baseColors[0] = new THREE.Color('#948C6A');
    baseColors[1] = new THREE.Color('#CBCBC1');
    baseColors[2] = new THREE.Color('#9B7646');

    const updateColors = (colorMode) => {
        const baseColor = mulberry32(Date.now());
        for (let i = 0; i < 3; i++) {
            let hue = Math.random() * 360;
            let saturation = Math.random() * 40 + 10;
            let brightness = Math.random() * 50 + 40;
            switch (colorMode) {
                case 'triadic':
                    hue = baseColor * 360 + 120 * i;
                    if (hue > 360) {
                        hue = hue % 360;
                    }
                    break;
                case 'analogous':
                    if (baseColor * 360 + i * 20 > 360) {
                        hue = baseColor * 360 - i * 20;
                    } else {
                        hue = baseColor * 360 + i * 20;
                    }
                    break;
            }
            const color = [hue, saturation + '%', brightness + '%'];
            let cssColor = [hue, saturation + '%', Math.min(brightness, 60) + '%'];
            baseColors[i] = new THREE.Color(`hsl(${color[0]}, ${color[1]}, ${color[2]})`);
            if (i === 0) {
                cssColor = `hsl(${cssColor[0]} ${cssColor[1]} ${cssColor[2]})`;
                document.documentElement.style.setProperty('--primary-color', cssColor);
            }
        }
        setTimeout(updateTerrain, 400);
    }

    const randomizers = document.querySelectorAll('.js-randomizer');
    const canvasWrapper = document.querySelector('.js-canvas-wrapper');
    randomizers.forEach(randomizer => {
        randomizer.addEventListener('click', () => {
            document.body.classList.add('is-loading');
            const colorMode = randomizer.dataset.mode;
            updateColors(colorMode);
        });
    });

    // Update terrain vertices
    let commonGrassSampler = new MeshSurfaceSampler(terrain).setWeightAttribute('commonGrass').build();
    let tallGrassSampler = new MeshSurfaceSampler(terrain).setWeightAttribute('tallGrass').build();

    const updateTerrain = () => {
        seed(Date.now());
        for (let i = 0; i < verticesCount; i++) {
            const x = terrainGeometry.attributes.position.getX(i);
            const y = terrainGeometry.attributes.position.getY(i);
            const highestPoint = verticesCount / 3;
            const lowestPoint = verticesCount / 2;
            const factor = 0.2;
            let strength = 1;
            // Make the terrain flatter in front of the camera
            if (i > highestPoint && i < lowestPoint) {
                strength = factor + (1 - factor) * normalize(i, highestPoint, lowestPoint);
            } else if (i > highestPoint) {
                strength = factor;
            }
            const terrainShapeHeight = 2.2;
            const terrainShapeGrain = 0.1;
            const terrainShape = Math.abs(perlin2(x * terrainShapeGrain, y * terrainShapeGrain)) * terrainShapeHeight * strength;
            const largeBumpsHeight = 2.5;
            const largeGrain = 0.2;
            const offset = 0.5;
            const largeBumps = (perlin2(x * largeGrain, y * largeGrain) + offset) * largeBumpsHeight * strength;
            const smallBumpsHeight = 0.02;
            const smallGrain = 2;
            const smallBumps = perlin2(x * smallGrain, y * smallGrain) * smallBumpsHeight;
            terrainGeometry.attributes.position.setZ(i, terrainShape + largeBumps + smallBumps);
        }
        terrainGeometry.computeVertexNormals();
        terrainGeometry.attributes.position.needsUpdate = true;

        const uv = new THREE.Vector2();
        const normal = new THREE.Vector3();
        const normals = [];
        const growMap = [];
        const baseVector = new THREE.Vector3(0, -1, 0);
        const commonGrassMap = [];
        const tallGrassMap = [];

        const defaultColor = new THREE.Color();
        const colors = [];
        for (let i = 0; i < terrainGeometry.attributes.uv.count; i++) {
            uv.fromBufferAttribute(terrainGeometry.attributes.uv, i);
            normal.fromBufferAttribute(terrainGeometry.attributes.normal, i);
            const facingCamera = normal.normalize().angleTo(baseVector) < Math.PI;
            normals.push(facingCamera);
            // Grass variations
            const grassVariation = Math.abs(perlin2(uv.x * 2, uv.y * 2)) * 2;
            const growArea = grassVariation >= 1/6 ? 1 : Math.pow(grassVariation / (1/6), 3);
            const growAreaVariation = Math.abs(perlin2(uv.x * 6, uv.y * 6)) * 2;
            const tallGrassVariation = facingCamera && growArea === 1 && growAreaVariation < 1/12 ? 1 : 0;
            const commonGrassVariation = facingCamera && growArea === 1 && growAreaVariation >= 1/12 ? 1 : 0;
            commonGrassMap.push(...[commonGrassVariation, commonGrassVariation, commonGrassVariation]);
            tallGrassMap.push(...[tallGrassVariation, tallGrassVariation, tallGrassVariation]);
            growMap.push(...[growArea, growArea, growArea]);

            // Color variations
            const colorVariation = Math.abs(perlin2(uv.x * 5, uv.y * 5)) * 2;
            const color1Strength = colorVariation < 1/3 ? 1 : 0;
            const color2Strength = colorVariation >= 1/3 && colorVariation < 2/3 ? 1 : 0;
            const color3Strength = colorVariation > 2/3 ? 1 : 0;
            const color = defaultColor.lerp(baseColors[0], color1Strength * 0.8).lerp(baseColors[1], color2Strength * 0.8).lerp(baseColors[2], color3Strength * 0.8);
            colors.push(...[color.r, color.g, color.b]);
        }

        terrainGeometry.setAttribute('growArea', new THREE.Float32BufferAttribute(growMap, 3));
        terrainGeometry.setAttribute('commonGrass', new THREE.Float32BufferAttribute(commonGrassMap, 3));
        terrainGeometry.setAttribute('tallGrass', new THREE.Float32BufferAttribute(tallGrassMap, 3));
        terrainGeometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
        commonGrassSampler = new MeshSurfaceSampler(terrain).setWeightAttribute('commonGrass').build();
        tallGrassSampler = new MeshSurfaceSampler(terrain).setWeightAttribute('tallGrass').build();
        if (commonGrass) {
            commonGrass.params.sampler = commonGrassSampler;
            commonGrass.sample();
        }
        if (tallGrass) {
            tallGrass.params.sampler = tallGrassSampler;
            tallGrass.sample();
        }

        setTimeout(() => {
            document.body.classList.remove('is-loading');
        }, 100);
    }

    updateTerrain();

    terrainMaterial.onBeforeCompile = shader => {
        shader.vertexShader = shader.vertexShader.replace(
        '#include <common>',
            `
                #include <common>

                attribute float growArea;

                varying float vGrowArea;
            `
        );

        shader.vertexShader = shader.vertexShader.replace(
            '#include <begin_vertex>',
            `
                #include <begin_vertex>

                vGrowArea = growArea;
            `
        );

        shader.fragmentShader = shader.fragmentShader.replace(
        '#include <common>',
            `
                #include <common>

                varying float vGrowArea;
            `
        );

        shader.fragmentShader = shader.fragmentShader.replace(
            '#include <map_fragment>',
            `
                #include <map_fragment>

                diffuseColor = mix(diffuseColor, vec4(0.0, 0.0, 0.0, 1.0), vGrowArea * 0.7);
            `
        );
    }

    /* Common grass */
    const commonGrass = new Grass({
        height: 0.5,
        thickness: 1.2,
        waviness: 3.5,
        roughness: 0.3,
        windIntensity: 0.1,
        density: 120000,
        animate: true,
        sampler: commonGrassSampler,
        gui,
        guiFolderName: 'Common grass'
    });
    ground.add(commonGrass.blade);

    /* Tall grass */
    const tallGrass = new Grass({
        tilt: 0.4,
        bending: 0.5,
        height: 1.2,
        thickness: 2.3,
        roughness: 0.1,
        waviness: 0.4,
        color: '#b9912e',
        windIntensity: 0.1,
        density: 10000,
        animate: true,
        spacing: 0.1,
        sampler: tallGrassSampler,
        gui,
        guiFolderName: 'Tall grass'
    });
    ground.add(tallGrass.blade);

    ground.add(terrain);

    scene.scene.add(ground);
    ground.rotation.x = -Math.PI / 2;
    ground.rotation.z = Math.PI / 3;

    /* Sphere */
    // Geometry
    const sphereGeometry = new THREE.SphereGeometry(3.5, 64, 64);

    // Textures
    const colorTexture = textureLoader.load(materialColor);
    colorTexture.encoding = THREE.sRGBEncoding;
    const heightTexture = textureLoader.load(materialHeight);
    const normalTexture = textureLoader.load(materialNormal);
    const roughnessTexture = textureLoader.load(materialRoughness);

    // Material
    const sphereMaterial = new THREE.MeshStandardMaterial({
        color: '#D2B3AD',
        map: colorTexture,
        displacementMap: heightTexture,
        displacementScale: 0.05,
        normalMap: normalTexture,
        roughnessMap: roughnessTexture
    });

    // Mesh
    const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
    sphere.castShadow = true;
    sphere.receiveShadow = true;
    ground.add(sphere);
    sphere.rotation.x = Math.PI / 2;
    sphere.position.z = 3;
    sphere.position.x = sphereParams.collapsed.day.x;

    /* Raycaster */
    const raycaster = new THREE.Raycaster();
    raycaster.firstHitOnly = true;
    const pointer = new THREE.Vector2();
    let mouseIntersect = raycaster.intersectObject(sphere);

    /* Camera */
    scene.camera.position.z = 10;
    scene.camera.position.x = 20;
    scene.camera.position.y = 8;
    scene.controls.target = new THREE.Vector3(0, 3, 0);
    scene.controls.update();
    scene.scene.add(scene.camera);

    /* Environment */
    scene.scene.background = lightParams[mode].bgColor;

    debug.testBg = '#E7E2DD';
    if (gui) {
        const envFolder = gui.addFolder('Environment');
        envFolder.addColor(debug, 'testBg').name('skyColor').onChange(() => {
            scene.scene.background = new THREE.Color(debug.testBg);
        });
    }

    /* Lights */
    // Ambient light
    const ambientLight = new THREE.AmbientLight('#2a3034', 1);
    scene.scene.add(ambientLight);
    debug.ambientLightColor = '#2a3034';

    // Directional light
    const directionalLight = new THREE.DirectionalLight(lightParams[mode].color, 1.2);
    debug.directionalLightColor = lightParams[mode].color;
    directionalLight.position.set(lightParams[mode].x, lightParams[mode].y, -10);
    directionalLight.rotation.x = -Math.PI / 2;
    directionalLight.rotation.z = Math.PI / 3;
    scene.scene.add(directionalLight);

    if (gui) {
        const lightFolder = gui.addFolder('Light');
        lightFolder.addColor(debug, 'ambientLightColor').name('ambientLightColor').onChange(() => {
            ambientLight.color.set(debug.ambientLightColor)
        });
        lightFolder.add(ambientLight, 'intensity').min(0).max(1).step(0.01).name('ambientLightIntensity');
        lightFolder.addColor(debug, 'directionalLightColor').name('directionalLightColor').onChange(() => {
            directionalLight.color.set(debug.directionalLightColor)
        });
        lightFolder.add(directionalLight.position, 'x').min(-400).max(400).step(0.001).name('lightX');
        lightFolder.add(directionalLight.position, 'y').min(-100).max(100).step(0.001).name('lightY');
        lightFolder.add(directionalLight.position, 'z').min(-10).max(300).step(0.001).name('lightZ');
        lightFolder.add(directionalLight, 'intensity').min(0).max(10).step(0.001).name('lightIntensity');
    }

    /* Post-processing */
    scene.renderer.outputEncoding = THREE.sRGBEncoding;

    /* Interractions */
    // Move camera on mousemove
    scene.controls.minPolarAngle = 2 * Math.PI / 5;
    scene.controls.maxPolarAngle = 0.85 * Math.PI / 2;
    scene.controls.minAzimuthAngle = 0.9 * Math.PI / 3;
    scene.controls.maxAzimuthAngle = Math.PI / 2;
    scene.controls.rotateSpeed = 0.03;
    document.addEventListener('mousemove', e => {
        if (whatInput.ask('intent') === 'touch') return;
        scene.controls.handleMouseMoveRotate(e);
    });
    document.addEventListener('touchmove', e => {
        scene.controls.handleMouseMoveRotate(e.touches[0]);
    });

    // Update pointer position for Three.js
    window.addEventListener('mousemove', e => {
        if (whatInput.ask('intent') === 'touch') return;
        pointer.x = e.clientX / sizes.width * 2 - 1;
        pointer.y = -(e.clientY / sizes.height * 2 - 1);
    });
    window.addEventListener('touchstart', e => {
        if (whatInput.ask('intent') !== 'touch') return;
        pointer.x = e.touches[0].clientX / sizes.width * 2 - 1;
        pointer.y = -(e.touches[0].clientY / sizes.height * 2 - 1);
    });

    // Manage hover/click on toggle
    const toggle = document.querySelector('.js-toggle');
    toggle.checked = mode === 'night';
    let toggleState = 'collapsed';
    toggle.dataset.state = toggleState;
    let grabbing = false;
    let onEnterPosition;
    let onEnterRotation;
    let onLeavePosition;
    let onLeaveRotation;
    let modeChangePosition;
    let modeChangeRotation;
    let isSwitching = false;
    let toggleWidth = toggle.clientWidth;
    let toggleRect = toggle.getBoundingClientRect();
    const tl = gsap.timeline({ paused: true });

    let grabProgress;
    window.addEventListener('mouseup', () => {
        if (whatInput.ask('intent') === 'touch') return;
        onWindowUp();
    });
     window.addEventListener('touchend', () => {
        onWindowUp();
    });

    tl.addLabel('day')
      .to(debug, {
        directionalLightColor: lightParams.night.color,
        lightX: lightParams.night.x,
        lightY: lightParams.night.y,
        grassSaturation: lightParams.night.grassSaturation,
        grassBrightness: lightParams.night.grassBrightness,
        duration: 1.6,
        onUpdate: () => {
            directionalLight.color.set(debug.directionalLightColor);
            directionalLight.position.x = debug.lightX;
            directionalLight.position.y = debug.lightY;
            tallGrass.blade.material.uniforms.uSaturation.value = debug.grassSaturation;
            tallGrass.blade.material.uniforms.uBrightness.value = debug.grassBrightness;
            commonGrass.blade.material.uniforms.uSaturation.value = debug.grassSaturation;
            commonGrass.blade.material.uniforms.uBrightness.value = debug.grassBrightness;
        }
    }, '<')
      .to(debug.bgColor, {
        r: lightParams.night.bgColor.r,
        g: lightParams.night.bgColor.g,
        b: lightParams.night.bgColor.b,
        duration: 1.6,
        onUpdate: () => {
            scene.scene.background = debug.bgColor;
        }
    }, '<').addLabel('night');

    toggle.addEventListener('mouseenter', () => {
        if (whatInput.ask('intent') === 'touch') return;
        toggleWidth = toggle.clientWidth * 1.7;
        onEnter();
    });
    toggle.addEventListener('focus', () => {
        if (whatInput.ask('intent') !== 'keyboard') return;
        toggleWidth = toggle.clientWidth * 1.7;
        onEnter();
    });

    toggle.addEventListener('mouseleave', () => {
        if (whatInput.ask('intent') === 'touch') return;
        onLeave();
    });
    toggle.addEventListener('blur', () => {
        if (whatInput.ask('intent') !== 'keyboard') return;
        onLeave();
    });

    let mouseDelta = 0;
    toggle.addEventListener('click', e => {
        if (whatInput.ask('intent') === 'keyboard') return;
        e.preventDefault();
    });
    toggle.addEventListener('mousedown', e => {
        if (whatInput.ask('intent') === 'touch') return;
        onDown(e);
    });
    toggle.addEventListener('mouseup', e => {
        if (whatInput.ask('intent') === 'touch') return;
        onUp(e);
    });
    toggle.addEventListener('mousemove', e => {
        if (whatInput.ask('intent') === 'touch') return;
        onMove(e);
    });
    toggle.addEventListener('touchstart', e => {
        e.preventDefault();
        onDown(e.touches[0]);
    });
    toggle.addEventListener('touchend', e => {
        onUp(e.changedTouches[0]);
        if (toggleState !== 'collapsed') {
            setTimeout(() => {
                onLeave();
            }, 10);
        }
    });
    toggle.addEventListener('touchmove', e => {
        if (grabbing && !isSwitching && toggleState !== 'expanded') {
            toggleWidth = toggle.clientWidth;
            onEnter();
        }
        setTimeout(() => {
            onMove(e.touches[0]);
        }, 10);
    }, { passive: true })

    toggle.addEventListener('change', () => {
        onChange();
    });

    const onDown = (e) => {
        toggleRect = e.target.getBoundingClientRect();
        const offsetX = e.pageX - toggleRect.left;
        const offsetY = e.pageY - toggleRect.top;
        mouseDelta = offsetX + offsetY;
        setTimeout(() => {
            if (mouseIntersect.length && mouseIntersect.length < 2) {
                grabbing = true;
            }
        }, 10);
    }

    const onUp = (e) => {
        grabbing = false;
        toggleRect = e.target.getBoundingClientRect();
        const offsetX = e.pageX - toggleRect.left;
        const offsetY = e.pageY - toggleRect.top;
        mouseDelta = offsetX + offsetY - mouseDelta;
        if (Math.abs(mouseDelta) < 2) {
            toggle.checked = !toggle.checked;
            onChange();
        }
    }

    const onWindowUp = () => {
        if (grabProgress >= 0.5) {
            toggle.checked = true;
            onChange();
        } else if (grabProgress < 0.5) {
            toggle.checked = false;
            onChange();
        }
        grabProgress = undefined;
    }

    const onMove = (e) => {
        if (grabbing && !isSwitching) {
            const offsetX = e.pageX - toggleRect.left;
            const sphereCenter = toggleWidth / 4;
            if (offsetX > sphereCenter && offsetX < toggleWidth - sphereCenter) {
                grabProgress = (offsetX - sphereCenter) / (toggleWidth - sphereCenter * 2);
                tl.progress(grabProgress);
                if (onEnterPosition) onEnterPosition.kill();
                if (onEnterRotation) onEnterRotation.kill();
                if (onLeavePosition) onLeavePosition.kill();
                if (onLeaveRotation) onLeaveRotation.kill();
                if (modeChangePosition) modeChangePosition.kill();
                if (modeChangeRotation) modeChangeRotation.kill();
                sphere.position.x = sphereParams[toggleState].day.x + sphereParams[toggleState].day.xDiff * grabProgress;
                sphere.rotation.z = sphereParams[toggleState].day.rotation + sphereParams[toggleState].day.rotationDiff * grabProgress;
            }
        }
        if (grabbing && mouseIntersect.length && mouseIntersect.length < 2) {
            toggle.style.cursor = 'grabbing';
        } else if (mouseIntersect.length && mouseIntersect.length < 2) {
            toggle.style.cursor = 'grab';
        } else {
            toggle.style.cursor = '';
        }
    }

    const onEnter = () => {
        toggleState = 'expanded';
        toggle.dataset.state = toggleState;
        onEnterPosition = gsap.to(sphere.position, {
            x: sphereParams.expanded[mode].x,
            duration: 0.8,
            ease: 'expo.out',
            onStart: () => {
                if (modeChangePosition) {
                    modeChangePosition.kill();
                }
            }
        });
        onEnterRotation = gsap.to(sphere.rotation, {
            z: sphereParams.expanded[mode].rotation,
            duration: 0.8,
            ease: 'expo.out',
            onStart: () => {
                if (modeChangeRotation) {
                    modeChangeRotation.kill();
                }
            }
        });
    }

    const onLeave = () => {
        grabbing = false;
        toggleState = 'collapsed';
        toggle.dataset.state = toggleState;
        onLeavePosition = gsap.to(sphere.position, {
            x: sphereParams.collapsed[mode].x,
            duration: 0.8,
            ease: 'expo.out',
            onStart: () => {
                if (modeChangePosition) {
                    modeChangePosition.kill()
                }
            }
        });
        onLeaveRotation = gsap.to(sphere.rotation, {
            z: sphereParams.collapsed[mode].rotation,
            duration: 0.8,
            ease: 'expo.out',
            onStart: () => {
                if (modeChangeRotation) {
                    modeChangeRotation.kill()
                }
            }
        });
    }

    const onChange = () => {
        mode = toggle.checked ? 'night': 'day';
        isSwitching = true;
        
        if (toggle.checked) {
            document.body.classList.add(classes.dark);
            document.body.dataset.mode = 'dark';
        } else {
            document.body.classList.remove(classes.dark);
            document.body.dataset.mode = 'light';
        }

        document.dispatchEvent(modeChangedEvent);

        modeChangePosition = gsap.to(sphere.position, {
            x: sphereParams[toggleState][mode].x,
            duration: 1.6,
            ease: 'expo.out'
        });
        modeChangeRotation = gsap.to(sphere.rotation, {
            z: sphereParams[toggleState][mode].rotation,
            duration: 1.6,
            ease: 'expo.out'
        });

        tl.tweenTo(mode, {
            ease: 'expo.out',
            onComplete: () => {
                isSwitching = false;
            }
        });
    }

    onChange();

    /* Animate scene */
    const clock = new THREE.Clock();

    const animateScene = () => {
        if (stats) {
            stats.begin();
        }

        window.requestAnimationFrame(animateScene);

        if (canvasWrapper.matches('.is-expanded')) return;

        const elapsedTime = clock.getElapsedTime();
        uniforms.uTime.value = elapsedTime;

        scene.renderer.render(scene.scene, scene.camera);

        // Update raycaster
        raycaster.setFromCamera(pointer, scene.camera);
        mouseIntersect = raycaster.intersectObject(sphere);

        // Remove default OrbitControls event listeners
        scene.controls.dispose();
        scene.controls.update();

        if (stats) {
            stats.end();
        }
    }

    const toggleLoader = document.querySelector('.js-toggle-loader');
    const toggleLoaderPaths = toggleLoader.querySelectorAll('.js-path');
    toggleLoaderPaths.forEach(path => {
        const length = Math.ceil(path.getTotalLength()) + 1;
        path.style.strokeDasharray = length;
        path.style.strokeDashoffset = length;
    });

    manager.onStart = () => {
        toggleLoaderPaths.forEach(path => {
            const length = Math.ceil(path.getTotalLength()) + 1;
            path.style.strokeDashoffset = length / 2;
        });
    };

    manager.onLoad = () => {
        document.dispatchEvent(new CustomEvent('webglLoaded'));
        document.body.classList.add('webgl-is-loaded');

        // Remove 'touch-action: none;' on canvas
        canvas.style.touchAction = '';

        toggleLoaderPaths.forEach(path => {
            path.style.strokeDashoffset = 0;
        });

        animateScene();
    }
}

export default buildScene;