const selectors = {
    el: '.js-local-time'
}

const setTime = (el, formatter) => {
    const now = formatter.format(new Date());
    el.innerHTML = now;
}

const module = () => {
    const el = document.querySelector(selectors.el);
    const formatter = new Intl.DateTimeFormat([], {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: 'Europe/Brussels',
        timeZoneName: 'shortOffset'
    });

    setTime(el, formatter);

    setInterval(() => {
        setTime(el, formatter);
    }, 1000);
}

export default module;