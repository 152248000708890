import * as THREE from 'three';
// Shaders
import bladeVertexShader from '../../shaders/grass/blade/vertex.glsl';
import bladeFragmentShader from '../../shaders/grass/blade/fragment.glsl';

/**
 * @class Grass
 * 
 * @method constructor
 * @method buildGrass
 * @method updatePosition
 * @method buildGui
 * @method animateGrass
 *
 * Create a grass object
 */
export default class Grass {
    /**
     * @method constructor
     * 
     * @param {object} params - Grass parameters
     */
    constructor (params = {}) {
        const {
            height = 1,
            tilt = 0.1,
            bending = 0.5,
            thickness = 2,
            roughness = 0.5,
            waviness = 1.2,
            windIntensity = 0.15,
            windSpeed = 0.6, 
            density = 10,
            spacing = 0.3,
            color = '#c54725',
            sampler,
            gui,
            guiFolderName = 'Grass',
            animate = false
        } = params;

        this.params = {};
        this.params.height = height;
        this.params.tilt = tilt;
        this.params.bending = bending;
        this.params.thickness = thickness;
        this.params.roughness = roughness;
        this.params.waviness = waviness;
        this.params.windIntensity = windIntensity;
        this.params.windSpeed = windSpeed;
        this.params.density = density;
        this.params.spacing = spacing;
        this.params.color = color;
        this.params.sampler = sampler;
        this.params.gui = gui;
        this.params.guiFolderName = guiFolderName;
        this.params.animate = animate;

        this.bladeHeight = 1;

        this.buildGrass();
    }

    /**
     * @method buildGrass
     */
    buildGrass () {
        // Geometry
        this.bladeGeometry = new THREE.PlaneGeometry(0.05, this.bladeHeight, 1, 8);
        
        // Material
        this.bladeMaterial = new THREE.ShaderMaterial({
            vertexShader: bladeVertexShader,
            fragmentShader: bladeFragmentShader,
            // lights: true,
            uniforms: {
                uTime: { value: 0 },
                uTilt: { value: this.params.tilt },
                uBending: { value: this.params.bending },
                uRoughness: { value: this.params.roughness },
                uWaviness: { value: this.params.waviness },
                uThickness: { value: this.params.thickness },
                uBaseHeight: { value: this.bladeHeight },
                uHeight: { value: this.params.height },
                uWindIntensity: { value: this.params.windIntensity },
                uWindSpeed: { value: this.params.windSpeed },
                uColor: { value: new THREE.Color(this.params.color) },
                uSaturation: { value: 1 },
                uBrightness: { value: 1 }
            },
            side: THREE.DoubleSide
        });

        // Mesh
        this.blade = new THREE.InstancedMesh(this.bladeGeometry, this.bladeMaterial, this.params.density);

        // Create an attribute per instance to get variations in the shader
        const indexes = new Float32Array([...Array(this.params.density).keys()]);
        const indexesAttribute = new THREE.InstancedBufferAttribute(indexes, 1);
        this.blade.geometry.setAttribute('instanceIndex', indexesAttribute);
        
        this.blade.instanceMatrix.needsUpdate = true;

        if (this.params.sampler) {
            this.sample();
        } else {
            this.updatePosition();
        }

        if (this.params.gui) {
            this.buildGui();
        }

        if (this.params.animate) {
            this.animateGrass();
        }
    }

    /**
     * @method sample
     */
    sample () {
        const position = new THREE.Vector3();
        const normal = new THREE.Vector3();
        const color = new THREE.Color();
        const matrix = new THREE.Matrix4();

        for (let i = 0; i < this.params.density; i++) {
            this.params.sampler.sample(position, normal, color);
            matrix.makeTranslation(position.x, position.y, position.z);
            this.blade.setMatrixAt(i, matrix);
            this.blade.setColorAt(i, color);
        }

        this.blade.instanceColor.needsUpdate = true;
        this.blade.instanceMatrix.needsUpdate = true;
    }

    /**
     * @method updatePosition
     */
    updatePosition () {
        const dummy = new THREE.Object3D();
        const spacing = this.params.spacing;

        for (let i = 0; i < this.params.density; i++) {
            dummy.position.set((0.5 - Math.random()) * spacing, 0, (0.5 - Math.random()) * spacing);
            dummy.updateMatrix();
            this.blade.setMatrixAt(i, dummy.matrix);
        }
        this.blade.instanceMatrix.needsUpdate = true;
    }

    /**
     * @method buildGui
     */
    buildGui () {
        const grassFolder = this.params.gui.addFolder(this.params.guiFolderName);

        grassFolder.add(this.params, 'tilt').min(0).max(2).step(0.001).name('bladeTilt').onChange(() => {
            this.bladeMaterial.uniforms.uTilt.value = this.params.tilt;
        });
        grassFolder.add(this.params, 'bending').min(0).max(2).step(0.001).name('bladeBending').onChange(() => {
            this.bladeMaterial.uniforms.uBending.value = this.params.bending;
        });
        grassFolder.add(this.params, 'height').min(0.5).max(3).step(0.001).name('bladeHeight').onChange(() => {
            this.bladeMaterial.uniforms.uHeight.value = this.params.height;
        });
        grassFolder.add(this.params, 'thickness').min(0.1).max(3).step(0.001).name('bladeThickness').onChange(() => {
            this.bladeMaterial.uniforms.uThickness.value = this.params.thickness;
        });
        grassFolder.add(this.params, 'roughness').min(0.1).max(2).step(0.001).name('bladeRoughness').onChange(() => {
            this.bladeMaterial.uniforms.uRoughness.value = this.params.roughness;
        });
        grassFolder.add(this.params, 'waviness').min(0.1).max(8).step(0.001).name('bladeWaviness').onChange(() => {
            this.bladeMaterial.uniforms.uWaviness.value = this.params.waviness;
        });
        if (!this.params.sampler) {
            grassFolder.add(this.params, 'spacing').min(0.1).max(2).step(0.001).name('spacing').onChange(() => {
                this.updatePosition();
            });
        }
        grassFolder.add(this.params, 'windIntensity').min(0.1).max(1).step(0.001).name('windIntensity').onChange(() => {
            this.bladeMaterial.uniforms.uWindIntensity.value = this.params.windIntensity;
        });
        grassFolder.add(this.params, 'windSpeed').min(0.1).max(4).step(0.001).name('windSpeed').onChange(() => {
            this.bladeMaterial.uniforms.uWindSpeed.value = this.params.windSpeed;
        });
        grassFolder.addColor(this.params, 'color').name('color').onChange(() => {
            this.bladeMaterial.uniforms.uColor.value = new THREE.Color(this.params.color);
        });
    }

    /**
     * @method animateGrass
     */
    animateGrass () {
        const clock = new THREE.Clock();

        const animate = () => {
            window.requestAnimationFrame(animate);

            const elapsedTime = clock.getElapsedTime();

            this.bladeMaterial.uniforms.uTime.value = elapsedTime;
        }

        animate();
    }
}