import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const selectors = {
    canvas: '.js-canvas',
    canvasWrapper: '.js-canvas-wrapper',
    seed: '.js-seed',
    content: '.js-content'
}

const classes = {
    scrolled: 'is-scrolled',
    hidden: 'is-hidden',
    expanded: 'is-expanded'
}

const module = () => {
    const canvas = document.querySelector(selectors.canvas);
    const canvasWrapper = document.querySelector(selectors.canvasWrapper);
    let scaleFactorX = window.innerWidth / canvasWrapper.clientWidth;
    let scaleFactorY = (window.innerHeight + 200) / canvasWrapper.clientHeight;
    let scaleFactor = scaleFactorX > scaleFactorY ? scaleFactorX : scaleFactorY;
    let windowHeight = window.innerHeight;
    let windowWidth = window.innerWidth;

    const content = document.querySelector(selectors.content);
    let contentOffset = window.innerHeight - content.getBoundingClientRect().top;
    let height;
    const setHeight = () => {
        height = content.clientHeight + windowHeight * 1.5;
        document.body.style.height = height + 'px';
        contentOffset = window.innerHeight - content.getBoundingClientRect().top;
    }
    setHeight();

    const animateCanvas = () => {
        return gsap.to(canvasWrapper, {
            width: canvasWrapper.clientWidth * scaleFactor,
            height: canvasWrapper.clientHeight * scaleFactor,
            borderRadius: 0,
            scrollTrigger: {
                trigger: document.body,
                start: '50px top',
                end: `${windowHeight}px top`,
                scrub: true,
                onEnter: () => document.body.classList.add(classes.scrolled),
                onLeaveBack: () => {
                    document.body.classList.remove(classes.scrolled);
                    gsap.set(canvasWrapper, { clearProps: 'width,height' });
                },
                onLeave: () => canvasWrapper.classList.add(classes.expanded),
                onEnterBack: () => canvasWrapper.classList.remove(classes.expanded),
                invalidateOnRefresh: true
            }
        });
    }
    let canvasAnim = animateCanvas();

    let canvasWasRefreshed = false;
    gsap.to(canvas, {
        scale: 1.2,
        scrollTrigger: {
            trigger: document.body,
            start: '50px top',
            end: `${windowHeight}px top`,
            scrub: true,
            onLeaveBack: () => {
                gsap.set(canvas, { clearProps: 'transform' });
            },
            onRefresh: () => {
                if (canvasWasRefreshed) return;
                canvasWasRefreshed = true;
                gsap.set(canvas, { clearProps: 'transform' });
            }
        }
    });

    ScrollTrigger.addEventListener('refreshInit', () => {
        setHeight();
        // Prevent resize when the navbar pops up in mobile.
        if (window.innerWidth === windowWidth && Math.abs(windowHeight - window.innerHeight) < 200) return;
        windowHeight = window.innerHeight;
        windowWidth = window.innerWidth;
        scaleFactorX = window.innerWidth / canvasWrapper.clientWidth;
        scaleFactorY = (window.innerHeight + 200) / canvasWrapper.clientHeight;
        scaleFactor = scaleFactorX > scaleFactorY ? scaleFactorX : scaleFactorY;
        canvasAnim.kill();
        canvasAnim = animateCanvas();
        scrollAnim.kill();
        scrollAnim = animateScroll();
    });

    const animateScroll = () => {
        // Smooth scroll
        const values = { y: 0 };
        return gsap.to(values, {
            y: -(height - window.innerHeight * 1.5 - contentOffset),
            ease: 'none',
            onUpdate: () => {
                content.style.transform = `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ${values.y}, 0, 1)`;
            },
            scrollTrigger: {
                trigger: document.body,
                start: `${windowHeight}px top`,
                end: 'bottom bottom',
                scrub: 1.2,
                invalidateOnRefresh: true
            }
        });
    }
    let scrollAnim = animateScroll();

    const seed = document.querySelector(selectors.seed);
    ScrollTrigger.create({
        trigger: document.body,
        start: `${windowHeight}px top`,
        end: 'bottom bottom',
        scrub: true,
        onEnter: () => seed.classList.add(classes.hidden),
        onLeaveBack: () => seed.classList.remove(classes.hidden),
        invalidateOnRefresh: true
    });
}

export default module;