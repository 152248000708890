import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const selectors = {
    el: '.js-floating-image'
}

const module = () => {
    const els = document.querySelectorAll(selectors.el);

    els.forEach(el => {
        gsap.set(el, { y: '50vh' });
        gsap.to(el, {
            y: 0,
            ease: 'none',
            scrollTrigger: {
                trigger: el,
                start: 'top 50%',
                end: 'bottom 30%',
                scrub: 1,
                invalidateOnRefresh: true
            }
        });
    });
}

export default module;