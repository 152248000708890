import 'what-input';
import customProperties from './utils/custom-properties';
import surrealToggle from './modules/surreal-toggle';
import localTime from './modules/local-time';
import scroll from './modules/scroll';
import splitText from './modules/split-text';
import floatingImage from './modules/floating-image';
import blankSpace from './modules/blank-space';
import lazyload from './modules/lazy-load';
import scrollImages from './modules/scroll-images';

const initModules = () => {
    document.body.classList.remove('no-js');
    document.body.classList.add('js');
    surrealToggle();
}

const initAfterWebgl = () => {
    customProperties();
    localTime();
    scroll();
    splitText();
    floatingImage();
    blankSpace();
    lazyload();
    scrollImages();
}

if (document.readyState !== 'loading') {
    initModules();
} else {
    document.addEventListener('DOMContentLoaded', () => initModules());
}

document.addEventListener('webglLoaded', () => initAfterWebgl());

window.addEventListener('beforeunload', () => {
    window.scrollTo(0, 0);
});