import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const selectors = {
    container: '.js-scroll-images',
    img: '.js-scroll-images__img'
}

const module = () => {
    const containers = document.querySelectorAll(selectors.container);

    containers.forEach(container => {
        const imgs = container.querySelectorAll(selectors.img);

        imgs.forEach(img => {
            gsap.set(img, { opacity: 0 });
        });

        ScrollTrigger.create({
            trigger: container,
            start: 'top -30%',
            end: 'bottom -30%',
            scrub: true,
            onUpdate: self => {
                const idx = Math.floor(self.progress * (imgs.length - 1));
                imgs.forEach(img => {
                    img.style.opacity = 0;
                });
                imgs[idx].style.opacity = 1;
            },
            invalidateOnRefresh: true
        });
    });
}

export default module;