let windowWidth = 0;
let windowHeight = 0;

const setViewportDimensions = () => {
    const vw = window.innerWidth / 100 + 'px';
    document.documentElement.style.setProperty('--vw', vw);
    if (window.innerWidth === windowWidth && Math.abs(windowHeight - window.innerHeight) < 200) return;
    windowWidth = window.innerWidth;
    windowHeight = window.innerHeight;
    const vh = window.innerHeight / 100 + 'px';
    document.documentElement.style.setProperty('--vh', vh);
}

const module = () => {
    setViewportDimensions();
    window.addEventListener('resize', setViewportDimensions);
}

export default module;