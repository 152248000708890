import Splitting from 'splitting';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const selectors = {
    el: '.js-split-text'
}

const classes = {
    visible: 'is-visible'
}

const module = () => {
    const els = document.querySelectorAll(selectors.el);

    els.forEach(el => {
        const split = Splitting({ target: el, by: 'words' });
        const wordsLength = split[0].words.length - 1;

        ScrollTrigger.create({
            trigger: el,
            start: 'top -30%',
            end: 'bottom -30%',
            scrub: true,
            onUpdate: self => {
                const idx = Math.floor(self.progress * wordsLength);
                split[0].words.forEach((word, i) => {
                    if (i <= idx) {
                        word.classList.add(classes.visible);
                    } else {
                        word.classList.remove(classes.visible);
                    }
                });
            },
            onLeaveBack: () => split[0].words[0].classList.remove(classes.visible),
            invalidateOnRefresh: true
        });
    });
}

export default module;